import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./RentContractFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import RentContractFormStepOne from "./RentContractFormSteps/RentContractFormStepOne";
import RentContractFormStepTwo from "./RentContractFormSteps/RentContractFormStepTwo";
import RentContractFormStepThree from "./RentContractFormSteps/RentContractFormStepThree";
import RentContractFormStepFour from "./RentContractFormSteps/RentContractFormStepFour";

import { useEffect, useState } from "react";

const RentContractForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        servicePriorityContract: _initialValues?.servicePriorityContract?.uuid
          ? { value: _initialValues?.servicePriorityContract?.uuid }
          : null,
        rentContractType: _initialValues?.rentContractType?.uuid
          ? { value: _initialValues?.rentContractType?.uuid }
          : null,
        contact: _initialValues?.contact?.uuid
          ? {
              ..._initialValues?.contact,
              label: _initialValues?.contact?.name,
              uuid: _initialValues?.contact?.uuid,
            }
          : null,
        contactBuyer: _initialValues?.contactBuyer?.uuid
          ? {
              ..._initialValues?.contactBuyer,
              label: _initialValues?.contactBuyer?.name,
              uuid: _initialValues?.contactBuyer?.uuid,
            }
          : null,
        representative: _initialValues?.representative?.uuid
          ? {
              ..._initialValues?.representative,
              label: `${_initialValues?.representative?.name} ${_initialValues?.representative?.lastname}`,
              uuid: _initialValues?.representative?.uuid,
            }
          : null,
        customerAssistant: _initialValues?.customerAssistant?.uuid
          ? {
              ..._initialValues?.customerAssistant,
              label: `${_initialValues?.customerAssistant?.lastName} ${_initialValues?.customerAssistant?.firstName}`,
              uuid: _initialValues?.customerAssistant?.uuid,
            }
          : null,
      });
      return;
    }
    setInitialValues({
      ..._initialValues,
      uuid: null,
      contractNo: null,
      foreignContractNo: null,
      servicePriorityContract: _initialValues?.servicePriorityContract?.uuid
        ? { value: _initialValues?.servicePriorityContract?.uuid }
        : null,
      rentContractType: _initialValues?.rentContractType?.uuid
        ? { value: _initialValues?.rentContractType?.uuid }
        : null,
      contact: _initialValues?.contact?.uuid
        ? {
            ..._initialValues?.contact,
            label: _initialValues?.contact?.name,
            uuid: _initialValues?.contact?.uuid,
          }
        : null,
      contactBuyer: _initialValues?.contactBuyer?.uuid
        ? {
            ..._initialValues?.contactBuyer,
            label: _initialValues?.contactBuyer?.name,
            uuid: _initialValues?.contactBuyer?.uuid,
          }
        : null,
      representative: _initialValues?.representative?.uuid
        ? {
            ..._initialValues?.representative,
            label: `${_initialValues?.representative?.name} ${_initialValues?.representative?.lastname}`,
            uuid: _initialValues?.representative?.uuid,
          }
        : null,
      customerAssistant: _initialValues?.customerAssistant?.uuid
        ? {
            ..._initialValues?.customerAssistant,
            label: `${_initialValues?.customerAssistant?.lastName} ${_initialValues?.customerAssistant?.firstName}`,
            uuid: _initialValues?.customerAssistant?.uuid,
          }
        : null,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="RentContractForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <RentContractFormStepOne {...formProps} {...props} />
              <RentContractFormStepTwo {...formProps} {...props} />
              <RentContractFormStepThree {...formProps} {...props} />
              <RentContractFormStepFour {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default RentContractForm;
